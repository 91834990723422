import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ApiError } from "src/interfaces/api-error";
import parse from "html-react-parser";
import { AppContext } from "src/utils/context";
import { PasswordInput } from "src/components/password-input";
import { FIELD_COULD_NOT_BE_EMPTY } from "src/I18n/I18n-templates";
import { isNotEmpty } from "src/utils/is-not-empty";

export type IOTPPasswordProps = IAuthPieceProps;

export interface IOTPPasswordState extends IAuthPieceState {
  username?: string;
  consultantDocument?: string;
  session?: string;
  newPassword: string;
  newPasswordState?: string;
  newPasswordHelpText?: string;
  confirmNewPassword: string;
  confirmNewPasswordState?: string;
  confirmNewPasswordHelpText?: string;
  formHelperErrorMessage?: string | React.JSX.Element | React.JSX.Element[];
  formHelperState?: string;
}

export class OTPPassword extends AuthPiece<
  IOTPPasswordProps,
  IOTPPasswordState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  static contextType = AppContext;

  context!: React.ContextType<typeof AppContext>;

  public constructor(props: IOTPPasswordProps) {
    super(props);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleNewPasswordOnChange = this.handleNewPasswordOnChange.bind(this);
    this.handleConfirmNewPasswordOnChange =
      this.handleConfirmNewPasswordOnChange.bind(this);
    this.validateInputs = this.validateInputs.bind(this);

    this.refButton = React.createRef();
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    const { username, consultantDocument, session } = this.context.otpContext;
    this.setState({
      username,
      consultantDocument,
      session,
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }

  public render(): React.JSX.Element {
    return (
      <Container
        company={this.state?.company}
        isLoading={this.state?.isLoading}
      >
        {this.subTitle()}
        <PasswordInput
          className="row"
          style={styles.centerRow}
          i18n={this.props.i18n}
          placeholder="New password"
          helpText={this.state?.newPasswordHelpText}
          value={this.state?.newPassword}
          state={this.state?.newPasswordState}
          onKeyDown={this.handleOnKeyDown}
          onChange={this.handleNewPasswordOnChange}
        />
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container passwordInput">
            <Input
              id="confirmNewPassword"
              type="password"
              placeholder={this.props.i18n.get("Confirm password")}
              onChange={this.handleConfirmNewPasswordOnChange}
              helpText={this.state?.confirmNewPasswordHelpText}
              state={this.state?.confirmNewPasswordState}
              onKeyDown={this.handleOnKeyDown}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.verificationHelperText}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Button
              id="createPasswordButton"
              onClick={async (): Promise<void> => {
                await this.onCreatePasswordButtonClick();
              }}
              text={this.props.i18n.get("create new password")}
              itemRef={this.refButton}
              disabled={
                !this.state?.newPassword ||
                this.state?.newPasswordState !== "success" ||
                !this.state?.confirmNewPassword ||
                this.state?.confirmNewPasswordState !== "success"
              }
            />
          </div>
        </div>
      </Container>
    );
  }

  private handleNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>,
    newPasswordState = "",
    newPasswordHelpText = ""
  ): void {
    const newPassword = event.target.value;

    this.setState({
      newPassword,
      newPasswordHelpText,
      newPasswordState,
    });
    this.validateConfirmNewPassword(
      newPassword,
      this.state.confirmNewPassword ?? ""
    );
  }

  private handleConfirmNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    const confirmNewPassword = event.target.value;

    this.setState({
      confirmNewPassword,
    });
    this.validateConfirmNewPassword(
      this.state.newPassword ?? "",
      confirmNewPassword
    );
  }

  private validateConfirmNewPassword(
    newPassword: string,
    confirmNewPassword: string
  ): void {
    if (confirmNewPassword !== "") {
      const confirmNewPasswordState =
        confirmNewPassword === newPassword ? "success" : "error";
      const confirmNewPasswordHelpText =
        confirmNewPasswordState === "error"
          ? this.props.i18n.get("Passwords do not match")
          : "";

      this.setState({
        confirmNewPasswordState,
        confirmNewPasswordHelpText,
      });
    } else if (newPassword === "") {
      this.setState({
        confirmNewPasswordState: "",
        confirmNewPasswordHelpText: "",
      });
    } else {
      this.setConfirmNewPasswordError();
    }
  }

  private validateInputs(): boolean {
    if (!isNotEmpty(this.state?.newPassword)) {
      this.setNewPasswordError();
      return false;
    }
    if (!isNotEmpty(this.state?.confirmNewPassword)) {
      this.setConfirmNewPasswordError();
      return false;
    }
    if (this.state?.newPassword !== this.state?.confirmNewPassword) {
      this.setPasswordsMismatchError();
      return false;
    }
    if (
      this.state?.newPassword.length > 15 ||
      this.state?.confirmNewPassword.length > 15
    ) {
      this.setPasswordsMaxLengthError();
      return false;
    }
    return true;
  }

  private setNewPasswordError() {
    this.setState({
      newPasswordState: "error",
      newPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("New password")
      ),
    });
  }

  private setConfirmNewPasswordError() {
    this.setState({
      confirmNewPasswordState: "error",
      confirmNewPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Confirm new password")
      ),
    });
  }

  private setPasswordsMismatchError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("Passwords do not match"),
      formHelperState: "error",
    });
  }

  private setPasswordsMaxLengthError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "The password should have the max length of 15 characters"
      ),
      formHelperState: "error",
    });
  }

  private isInvalidPasswordError(error: ApiError): boolean {
    return (
      error.message.toLowerCase().includes("incorrect username or password") ||
      error.message
        .toLowerCase()
        .includes("it was not possible to recognize username type for value")
    );
  }

  private showInvalidPasswordError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "Invalid username or password"
      ),
      formHelperState: "error",
    });
  }

  private async onCreatePasswordButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperState: "",
        formHelperErrorMessage: "",
      });
      if (!this.validateInputs()) {
        return;
      }
      this.setState({
        isLoading: true,
      });

      await this.props.api.otpChangePassword({
        country: this.state?.country as string,
        company: this.state?.company,
        clientId: this.state?.clientId,
        redirectUrl: this.state?.redirectUri,
        username: this.state?.username?.replace(/ /g, "") as string,
        document: this.state?.consultantDocument,
        newPassword: this.state?.newPassword,
        session: this.state?.session as string,
      });

      const previousPage = "otp-password";
      this.setState({
        previousPage,
      });

      this.navigate("password-created", { ...this.state, previousPage });
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleApiErrors(error: ApiError): void {
    if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else if (this.isRoleError(error)) {
      this.showRoleError();
    } else if (this.isEmailEmptyError(error)) {
      this.showEmptyEmailError();
    } else if (this.isInvalidPasswordError(error)) {
      this.showInvalidPasswordError();
    } else {
      this.showGenericError();
    }
  }

  private isEmailEmptyError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      message.includes("email is empty") ||
      message.includes("no email") ||
      message.includes("valid e-mail")
    );
  }

  private showEmptyEmailError() {
    this.showError("Empty email");
  }

  private isRoleError(error: Error): boolean {
    const message = error.message.toLowerCase();
    return (
      message.includes("role not allowed") ||
      message.includes("does not have a valid role")
    );
  }

  private showRoleError() {
    this.showError("The user does not have a valid role");
  }

  private isNotFoundError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      error.status === 404 ||
      message.includes("failed to create") ||
      message.includes("user does not exist") ||
      message.includes("username not found")
    );
  }

  private showGenericError() {
    this.showError(
      "We were unable to process your request. Please try again later"
    );
  }

  private showNotFoundError() {
    this.showError("User does not exists");
  }

  private showError(message: string) {
    this.setState({
      formHelperErrorMessage: parse(this.props.i18n.get(message)),
      formHelperState: "error",
    });
  }

  private subTitle(): React.JSX.Element {
    return (
      <div className="row" style={styles.centerRow}>
        <div
          style={{
            width: "335px",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="caption"
            style={{ fontSize: "16px", color: "#333" }}
          >
            {this.props.i18n.get(
              "Fill in the fields below to create a new password"
            )}
          </Typography>
        </div>
      </div>
    );
  }
}
