export const validateEcuadorDocument = (document: string): boolean => {
  let isValid = false;
  if (document.length === 10) {
    const thirdDigit = parseInt(document.substring(2, 3));
    if (thirdDigit <= 6) {
      const coefficientValue = [2, 1, 2, 1, 2, 1, 2, 1, 2];
      const checkDigit = parseInt(document.substring(9, 10));
      let sum = 0;
      let digit = 0;

      for (let i = 0; i < document.length - 1; i++) {
        digit = parseInt(document.substring(i, i + 1)) * coefficientValue[i];
        sum += (digit % 10) + Math.floor(digit / 10);
      }

      sum = Math.round(sum);
      if (
        (Math.round(sum % 10) === 0 && Math.round(sum % 10) === checkDigit) ||
        10 - Math.round(sum % 10) === checkDigit
      ) {
        isValid = true;
      }
    }
  }
  return isValid;
};
