/* eslint-disable spellcheck/spell-checker */
import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Link, Input } from "../components";
import { Container } from "../components/container";
import { isNotEmpty } from "../utils/is-not-empty";
import { redirect } from "../utils/redirect";
import { decideQuerySeparator } from "../utils/query-builder";
import styles from "src/styles/styles";
import { FormHelperText } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { isTooManyRequestsError } from "src/utils/too-many-requests-validator";
import { attemptLimitExceededMessage } from "src/utils/attempt-limit-exceeded-message";
import { ApiError } from "src/interfaces/api-error";
import { environment } from "src/config/environment";
import { SignInResponse } from "src/api/interfaces/sigin-response";
import { eloIsEnabled, showCountryFlag } from "src/utils/elo-utils";
import { PasswordPolicyConfig } from "src/api/interfaces/fetch-password-policy-response";
import { getNewPassValidationConfig } from "src/config/parse-password-config";
import { PasswordValidationExpiredModal } from "src/components/password-validation-expired-modal";
import { PasswordValidationUpcomingExpirationModal } from "src/components/password-validation-upcoming-expiration-modal";
import { FIELD_COULD_NOT_BE_EMPTY } from "src/I18n/I18n-templates";
import { SocialLoginScreen } from "src/components/social-login-screen";
import { isPastDate } from "src/utils/date-utils";
import { validatePasswordPolicy } from "src/utils/password-validator";
import crypto from "crypto-js";

export type ISignInProps = IAuthPieceProps;

export interface ISignInBRState extends IAuthPieceState {
  username?: string;
  password?: string;
  session?: string;
  usernameState?: string;
  usernameHelpText?: string;
  passwordState?: string;
  passwordHelpText?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string;
  passwordExpired?: boolean;
  avoidPasswordValidation?: boolean;
  passwordWillExpire?: boolean;
  signInResult?: SignInResponse;
  expirationStartDate?: Date;
  passwordPolicy?: PasswordPolicyConfig[];
  validationStartDate?: Date;
}

export class SignInBR extends AuthPiece<ISignInProps, ISignInBRState> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: ISignInProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.getPasswordFromInput = this.getPasswordFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);

    this.refButton = React.createRef();
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    await this.handleAlreadySignedInUsers();

    this.props.cookies.createOrUpdateCookie("cookieParams", this.state);

    this.loadPasswordExpirationPolicy();
    await this.loadPasswordPolicy();
  }

  private loadPasswordExpirationPolicy(): void {
    const countryConfig = getNewPassValidationConfig(
      environment.newValidationConfig
    );
    this.setState({
      avoidPasswordValidation: false,
      expirationStartDate: countryConfig?.expirationStartDate,
    });
  }

  private async loadPasswordPolicy(): Promise<void> {
    const country = this.state?.country as string;
    const company = this.state?.company;
    const language = this.state?.language as string;

    try {
      const result = await this.props.api.fetchPasswordPolicyCache({
        country,
        company,
        language,
      });
      this.setState({
        passwordPolicy: result[language]?.[company]?.[country] ?? [],
      });
    } catch (err) {
      const apiError = err as ApiError;
      console.error("Unable to load password rules:", apiError.message);
    }
  }

  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  public getPasswordFromInput(): string | undefined {
    return this.state.password;
  }

  private getSendPassUrl(): string {
    return `${environment.secAccessPassUrl}acesso-seguro/identification?callback_url=${window.location.href}`;
  }

  private getSendPassUrlOrNavigationLink(route: string): string {
    return route === "forgot-password"
      ? this.getSendPassUrl()
      : this.buildNavigationLink(route);
  }

  public render(): React.JSX.Element {
    const country = this.state?.country ?? "";
    return (
      <Container
        country={showCountryFlag(this.state?.country, true)}
        company={this.state?.company}
        isLoading={this.state?.isLoading}
        hideBackButton={true}
      >
        <PasswordValidationUpcomingExpirationModal
          visible={this.state?.passwordWillExpire}
          onChoose={(resetPassword) => {
            this.setState({ passwordWillExpire: false });
            this.onChoosePasswordModal(resetPassword);
          }}
          i18n={this.props.i18n}
        />
        <PasswordValidationExpiredModal
          visible={this.state?.passwordExpired}
          onChoose={(resetPassword) => {
            this.setState({ passwordExpired: false });
            this.onChoosePasswordModal(resetPassword);
          }}
          i18n={this.props.i18n}
        />
        <div className="row" style={styles.centerRow}>
          <div style={{ width: "300px", margin: "10px" }}>
            <Input
              id="username"
              type="text"
              value={this.state?.username}
              placeholder={this.props.i18n.getUsernamePlaceholder(country)}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  username: event.target.value,
                  usernameState: "",
                  usernameHelpText: "",
                })
              }
              helpText={this.state?.usernameHelpText}
              state={this.state?.usernameState}
              onKeyDown={this.handleOnKeyDown}
              autoComplete="off"
            />
          </div>
        </div>

        <div className="row" style={styles.centerRow}>
          <div
            className="passwordInput"
            style={{ width: "300px", margin: "10px" }}
          >
            <Input
              id="password"
              type="password"
              placeholder={this.props.i18n.get("Password")}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  password: event.target.value,
                  passwordState: "",
                  passwordHelpText: "",
                })
              }
              helpText={this.state?.passwordHelpText}
              state={this.state?.passwordState}
              onKeyDown={this.handleOnKeyDown}
              autoComplete="off"
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.helperText}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "10px", width: "300px" }}>
            <Button
              id="signInButton"
              onClick={async (): Promise<void> => {
                await this.onSignInButtonClick(
                  this.state?.avoidPasswordValidation === true
                );
              }}
              text={this.props.i18n.get("login")}
              itemRef={this.refButton}
              keepEnabled={true}
            />
          </div>
        </div>
        {this.socialLoginScreen()}
        <div style={{ marginTop: "10px" }}>
          <Link
            style={{ fontWeight: "bold" }}
            text={this.props.i18n.get("Forgot password?")}
            url={this.getSendPassUrlOrNavigationLink("forgot-password")}
          />
        </div>
        <Link
          style={{ fontWeight: "bold" }}
          text={
            eloIsEnabled(this.state?.country)
              ? this.props.i18n.get("Resend first access email ELO")
              : this.props.i18n.get("Resend first access email")
          }
          url={this.getSendPassUrlOrNavigationLink("resend-temporary-password")}
        />
      </Container>
    );
  }

  private async onSignInButtonClick(
    avoidPasswordValidation: boolean
  ): Promise<void> {
    try {
      if (!this.validUserInput()) {
        return;
      }

      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        isLoading: true,
      });

      const { username, password, encryptedPass } = this.getSignInCredentials();
      const signInResult = await this.callSignInAPI(username, encryptedPass);

      if (
        this.shouldValidatePassword(
          signInResult,
          avoidPasswordValidation,
          password
        )
      ) {
        this.handlePasswordValidation();
        return;
      }

      this.loginFlow(signInResult);
    } catch (err) {
      const error = err as Error;
      this.setState({ isLoading: false });
      this.handleSignInError(error);
    }
  }

  private getSignInCredentials() {
    const username = this.state?.username?.trim() as string;
    const password = this.state?.password?.trim() as string;
    const encryptedPass = crypto.AES.encrypt(password, username).toString();
    return { username, password, encryptedPass };
  }

  private async callSignInAPI(
    username: string,
    encryptedPass: string
  ): Promise<SignInResponse> {
    return await this.props.api.signIn({
      clientId: this.state?.clientId,
      country: this.state?.country as string,
      company: this.state?.company,
      username: username,
      password: encryptedPass,
      redirectUrl: this.state.redirectUri,
    });
  }

  private shouldValidatePassword(
    signInResult: SignInResponse,
    avoidPasswordValidation: boolean,
    password: string
  ): boolean {
    return (
      !signInResult.challengeRequired &&
      !avoidPasswordValidation &&
      !environment.newPassValidationWhiteList.includes(
        password.toLocaleLowerCase()
      ) &&
      isPastDate(this.state.validationStartDate) &&
      !this.preValidatePassword()
    );
  }

  private handlePasswordValidation() {
    this.setState(
      isPastDate(this.state.expirationStartDate)
        ? { passwordExpired: true }
        : { passwordWillExpire: true }
    );
  }

  private handleSignInError(error: Error): void {
    if (isTooManyRequestsError(error)) {
      this.showPasswordAttemptsExceededError();
    } else if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else if (this.isInvalidPasswordError(error)) {
      this.showInvalidPasswordError();
    } else {
      this.showGenericError();
    }
  }

  private preValidatePassword(): boolean {
    if (this.state?.passwordPolicy?.length) {
      const password = this.state?.password?.replace(/ /g, "") as string;
      return validatePasswordPolicy(password, this.state.passwordPolicy);
    }
    return true;
  }

  private async loginFlow(signInResult: SignInResponse): Promise<void> {
    if (signInResult.challengeRequired === true) {
      const state = { ...this.state, session: signInResult.challenge.session };
      this.setState({ session: signInResult.challenge.session });
      this.navigate("first-access", state);
    } else {
      await this.saveUserSession(signInResult.sso_token);
      this.goBackToApplication(signInResult.sso_token);
    }
  }

  private onChoosePasswordModal(resetPassword?: boolean): void {
    if (resetPassword) {
      redirect(this.getSendPassUrl());
    } else {
      this.setState({ avoidPasswordValidation: true });
      this.onSignInButtonClick(true);
    }
  }

  private async handleAlreadySignedInUsers(): Promise<void> {
    this.setState({ isLoading: true });
    const cookieName = this.getCookieName();
    const ssoToken = this.props.cookies.getCookie(cookieName);
    try {
      if (ssoToken !== null && ssoToken !== undefined) {
        await this.props.api.recoverSession(
          ssoToken,
          this.state.redirectUri,
          this.state.clientId
        );
        this.goBackToApplication(ssoToken);
        return;
      }
    } catch (error: any) {
      if (
        !(error as Error).message.includes(
          "Invalid clientId and RedirectUrl combination"
        )
      ) {
        this.eraseUserSession();
      }
    }
    const isSSO = this.isSSO();
    this.setState({ isLoading: isSSO });
  }

  private goBackToApplication(ssoToken: string): void {
    redirect(
      `${this.state.redirectUri}${decideQuerySeparator(
        this.state.redirectUri
      )}sso_token=${ssoToken}`
    );
  }

  private async saveUserSession(ssoToken: string): Promise<void> {
    const cookieName = this.getCookieName();
    this.props.cookies.createCookie(cookieName, ssoToken);
  }

  private eraseUserSession(): void {
    const cookieName = this.getCookieName();
    this.props.cookies.removeCookie(cookieName);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state?.company}_id`.toLowerCase();
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    if (!isNotEmpty(this.state?.password)) {
      this.setPasswordError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private setUsernameError() {
    this.setState({
      usernameState: "error",
      usernameHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(
          this.props.i18n.getUsernamePlaceholder(this.state?.country)
        )
      ),
    });
  }

  private setPasswordError() {
    this.setState({
      passwordState: "error", //NOSONAR //pragma: allowlist secret
      passwordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Password")
      ),
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }

  private showGenericError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "We were unable to process your request. Please try again later"
      ),
      formHelperState: "error",
    });
  }

  private isInvalidPasswordError(error: Error): boolean {
    return (
      (error as ApiError).message
        .toLowerCase()
        .includes("Incorrect username or password".toLowerCase()) ||
      (error as ApiError).message
        .toLowerCase()
        .includes(
          "it was not possible to recognize username type for value".toLowerCase()
        )
    );
  }

  private showInvalidPasswordError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "Invalid username or password"
      ),
      formHelperState: "error",
    });
  }

  private isNotFoundError(error: Error): boolean {
    return (error as ApiError).status === 404;
  }

  private showNotFoundError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("User does not exists"),
      formHelperState: "error",
    });
  }

  private showPasswordAttemptsExceededError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        attemptLimitExceededMessage()
      ),
      formHelperState: "error",
    });
  }

  private socialLoginScreen(): React.ReactNode {
    return (
      <SocialLoginScreen
        i18n={this.props.i18n}
        country={this.state?.country}
        clientId={this.state?.clientId}
        cookies={this.props.cookies}
      />
    );
  }

  private isSSO(): boolean {
    let isSSO = false;
    if (this.state?.sso === "oam") {
      isSSO = true;
      const provider = "OAM";
      this.props.cookies.createOrUpdateCookie("cookieParams", { provider });
      window.location.href = `${environment.cognitoBrDomain}/oauth2/authorize?identity_provider=${provider}&redirect_uri=https://${window.location.hostname}/social-sign-in&response_type=CODE&client_id=${this.state.clientId}&scope=openid`;
    }
    return isSSO;
  }
}
