import React from "react";
import styles from "../styles/styles";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Logo } from "../components";
import { Typography, List, ListItem } from "@naturacosmeticos/natds-web";

export type IErrorProps = IAuthPieceProps;

export interface IErrorState extends IAuthPieceState {
  message?: string;
}

export class Error extends AuthPiece<IErrorProps, IErrorState> {
  public constructor(props: IErrorProps) {
    super(props);
    this.getErrorMessage = this.getErrorMessage.bind(this);
  }

  public getErrorMessage(): string | undefined {
    return this.state.message;
  }

  public async componentDidMount(): Promise<void> {
    const locationState = this.props.location?.state;
    if (locationState) {
      this.setState({ message: Object(locationState).message });
    }

    this.setState({ company: this.getCompany() as string });
  }

  private getQueryParamByName(name: string) {
    const searchInfo = this.props.location.search;
    const query = new URLSearchParams(searchInfo);
    return query.get(name);
  }

  public errorPersonalizedMessage():
    | string
    | React.JSX.Element[]
    | React.JSX.Element {
    if (this.getQueryParamByName("description") === "missing_parameters") {
      const missingParameters = this.getQueryParamByName("error");
      if (missingParameters) {
        return missingParameters
          .split(",")
          .map((error: string, index: number) => {
            return (
              <ListItem key={index}>
                <Typography color="error">
                  {`parameter ' ${error} ' is missing`}
                </Typography>
              </ListItem>
            );
          });
      }
    }

    if (this.getQueryParamByName("description") === "parameters_values") {
      const missingParameters = this.getQueryParamByName("error");
      if (missingParameters) {
        return missingParameters
          .split(",")
          .map((error: string, index: number) => {
            return (
              <ListItem key={index}>
                <Typography color="error">
                  {`unsupported param value: ${error}`}
                </Typography>
              </ListItem>
            );
          });
      }
    }

    if (this.getQueryParamByName("description") === "redirect_uri_mismatch") {
      const errorMessage = this.getQueryParamByName("error");
      if (errorMessage) {
        return (
          <ListItem>
            <Typography color="error">{errorMessage}</Typography>
          </ListItem>
        );
      }
    }

    if (this.getQueryParamByName("description") === "genericerror") {
      return (
        <ListItem>
          <Typography color="error">
            {`An error occurred. Please try again.`}
          </Typography>
        </ListItem>
      );
    }

    return "";
  }

  public render(): React.JSX.Element {
    return (
      <div style={styles.containerView}>
        <div style={styles.container}>
          <Logo />
          <div className="row" style={styles.errorPageView}>
            <List>
              {this.state?.message
                ? this.state?.message
                    .split(",")
                    .map((error: string, index: number) => {
                      return (
                        <ListItem key={index}>
                          <Typography color="error">{error}</Typography>
                        </ListItem>
                      );
                    })
                : this.errorPersonalizedMessage()}
            </List>
          </div>
        </div>
      </div>
    );
  }
}
