import React, { useMemo, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { environment } from "./config/environment";
import { dictionary } from "./I18n/dictionary";
import { I18n } from "./I18n/I18n";
import {
  ForgotPassword,
  ConfirmForgotPassword,
  ConfirmSignIn,
  SignIn,
  SignInBR,
  FirstAccess,
  FirstAccessBR,
  SignOut,
  ResendTemporaryPassword,
  RedirectToLogin,
  SocialSignIn,
  Error,
  ChangePassword,
  OTPIdentification,
  OTPVerification,
  OTPToken,
  OTPPassword,
  ErrorValidateOTP,
  PasswordCreated,
  Logout,
} from "./pages/";
import { CookiesService } from "./utils/cookie-service";
import { Provider } from "@naturacosmeticos/natds-web";
import { setFavicon, setTitle } from "./utils/elo-utils";
import {
  getClientId,
  getCountry,
  getLanguage,
  getSelectedTheme,
  getURLSearchParams,
  setThemeVars,
} from "./utils/query-params-utils";
import { enableDynatrace } from "./utils/dynatrace";
import { Verification } from "./pages/verification";
import { AppContext, AppContextDefault, IAppContext } from "./utils/context";
import { Maintenance } from "./pages/maintenance";
import { isUnderMaintenanceDates } from "./utils/maintenance-service-date";
import { createAPIInstance } from "./api/api-instance";

// eslint-disable-next-line
export default function App(props: any) {
  const urlSearchParams = getURLSearchParams();
  const country = getCountry(urlSearchParams);
  const clientId = getClientId(urlSearchParams);
  const isBR = country === "br";
  const language = getLanguage(urlSearchParams);
  const bypassMaintenance =
    urlSearchParams.get("maintenance") === "false" ||
    environment.maintenanceBypassClientID.includes(clientId);
  const isMaintenanceDate = isUnderMaintenanceDates(
    environment.maintenanceMode,
    country
  );

  const isUnderMaintenance =
    environment.maintenanceMode[country] && isMaintenanceDate;

  const authProps = useMemo(
    () => ({
      api: createAPIInstance(isBR),
      i18n: new I18n(dictionary),
      cookies: new CookiesService(),
    }),
    [isBR]
  );

  const appContext: IAppContext = useMemo(
    () => ({ ...AppContextDefault, i18n: authProps.i18n }),
    [authProps]
  );

  const theme = useMemo(() => {
    const selectedTheme = getSelectedTheme(urlSearchParams);
    setThemeVars(selectedTheme);
    return selectedTheme;
  }, [country]);

  useEffect((): void => {
    enableDynatrace();
  }, []);

  useEffect((): void => {
    setFavicon(country);
    setTitle(authProps.i18n, country, language);
  }, [country, language]);

  const handleMaintenanceMode = (): boolean => {
    if (urlSearchParams.get("maintenance") === "true") {
      return true;
    }
    if (bypassMaintenance) {
      return false;
    }
    if (isUnderMaintenance) {
      return true;
    }
    return false;
  };

  return (
    <Provider theme={theme}>
      <AppContext.Provider value={appContext}>
        <Router basename={"/auth"}>
          {handleMaintenanceMode() ? (
            <Route
              render={(props) => <Maintenance {...props} {...authProps} />}
            />
          ) : (
            <div>
              <Route
                exact
                path="/"
                render={(props) =>
                  isBR ? (
                    <SignInBR {...props} {...authProps} />
                  ) : (
                    <SignIn {...props} {...authProps} />
                  )
                }
              />
              <Route
                exact
                path="/confirm-sign-in"
                render={(props) => <ConfirmSignIn {...props} {...authProps} />}
              />
              <Route
                exact
                path="/forgot-password"
                render={(props) => <ForgotPassword {...props} {...authProps} />}
              />
              <Route
                exact
                path="/confirm-forgot-password"
                render={(props) => (
                  <ConfirmForgotPassword {...props} {...authProps} />
                )}
              />
              <Route
                exact
                path="/first-access"
                render={(props) =>
                  isBR ? (
                    <FirstAccessBR {...props} {...authProps} />
                  ) : (
                    <FirstAccess {...props} {...authProps} />
                  )
                }
              />
              <Route
                exact
                path="/change-password"
                render={(props) => <ChangePassword {...props} {...authProps} />}
              />
              <Route
                exact
                path="/resend-temporary-password"
                render={(props) => (
                  <ResendTemporaryPassword {...props} {...authProps} />
                )}
              />
              <Route
                exact
                path="/verification"
                render={(props) => <Verification {...props} {...authProps} />}
              />
              <Route
                exact
                path="/error"
                render={(props) => <Error {...props} {...authProps} />}
              />
              <Route
                exact
                path="/sign-out"
                render={(props) => <SignOut {...props} {...authProps} />}
              />
              <Route
                exact
                path="/redirect"
                render={(props) => (
                  <RedirectToLogin {...props} {...authProps} />
                )}
              />
              <Route
                exact
                path="/social-sign-in"
                render={(props) => <SocialSignIn {...props} {...authProps} />}
              />
              <Route
                exact
                path="/otp-identification"
                render={(props) => (
                  <OTPIdentification {...props} {...authProps} />
                )}
              />
              <Route
                exact
                path="/otp-verification"
                render={(props) => (
                  <OTPVerification {...props} {...authProps} />
                )}
              />
              <Route
                exact
                path="/otp-token"
                render={(props) => <OTPToken {...props} {...authProps} />}
              />
              <Route
                exact
                path="/otp-password"
                render={(props) => <OTPPassword {...props} {...authProps} />}
              />
              <Route
                exact
                path="/error-validate-otp"
                render={(props) => (
                  <ErrorValidateOTP {...props} {...authProps} />
                )}
              />
              <Route
                exact
                path="/password-created"
                render={(props) => (
                  <PasswordCreated {...props} {...authProps} />
                )}
              />
              <Route
                exact
                path="/logout"
                render={(props) => <Logout {...props} {...authProps} />}
              />
            </div>
          )}
        </Router>
      </AppContext.Provider>
    </Provider>
  );
}
