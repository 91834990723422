import { PasswordPolicyConfig } from "src/api/interfaces/fetch-password-policy-response";
import { environment } from "src/config/environment";
import { PasswordValidationData } from "src/interfaces/validate-password-data";

export const getInvalidPasswordValidationData = (): PasswordValidationData => ({
  isValid: false,
  isLong: false,
  isTooLong: false,
  hasNoSequence: false,
  hasNumber: false,
  hasLowerCase: false,
  hasUpperCase: false,
  hasSpecialCharacter: false,
  hasNoForbiddenWords: false,
});

export const passwordValidator = (
  pass: string,
  forbiddenWords: string[],
  minPasswordLength: number,
  maxPasswordLength: number
): PasswordValidationData => {
  if (pass === "") {
    return getInvalidPasswordValidationData();
  }
  const data = new PasswordValidationData();
  if (pass.length > maxPasswordLength) {
    data.isTooLong = pass.length <= maxPasswordLength;
    data.isValid = false;
  }
  if (pass.length < minPasswordLength) {
    data.isLong = false;
    data.isValid = false;
  }
  if (!/^(?=.*\d).*$/g.test(pass)) {
    data.hasNumber = false;
    data.isValid = false;
  }
  if (!/^(?=.*[a-z]).*$/g.test(pass)) {
    data.hasLowerCase = false;
    data.isValid = false;
  }
  if (!/^(?=.*[A-Z]).*$/g.test(pass)) {
    data.hasUpperCase = false;
    data.isValid = false;
  }
  if (!/^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/g.test(pass)) {
    data.hasSpecialCharacter = false;
    data.isValid = false;
  }
  if (!new RegExp(`^((?!${forbiddenWords.join("|")}).)*$`, "i").test(pass)) {
    data.hasNoForbiddenWords = false;
    data.isValid = false;
  }
  return data;
};

export const getAllMatchedForbiddenWords = (
  text: string,
  forbiddenWords: string[]
): string[] | null => {
  // eslint-disable-next-line spellcheck/spell-checker
  const regex = new RegExp(`(${forbiddenWords.join("|")})`, "gi");
  return text.match(regex);
};

const isWeakPassAllowedEnvironment = ["hml", "qa"].includes(
  environment.environment
);

export const validatePasswordPolicyByLib = (password: string): boolean => {
  const validation = passwordValidator(
    password,
    environment.forbiddenWords,
    environment.minPasswordLength,
    environment.maxPasswordLength
  );

  return isWeakPassAllowedEnvironment || validation.isValid;
};

export const validatePasswordPolicy = (
  password: string,
  passwordPolicy: PasswordPolicyConfig[]
): boolean => {
  for (const policy of passwordPolicy) {
    const regex = new RegExp(policy.regex, policy.flags);
    const regexResult = regex.test(password);
    if (!regexResult && !isWeakPassAllowedEnvironment) {
      return false;
    }
  }
  return true;
};
