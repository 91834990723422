import React, {
  ChangeEvent,
  KeyboardEvent,
  CSSProperties,
  useState,
} from "react";
import { Icon, Popover } from "@naturacosmeticos/natds-web";
import { Input } from "src/components";
import { I18n } from "src/I18n/I18n";
import {
  getAllMatchedForbiddenWords,
  getInvalidPasswordValidationData,
  passwordValidator,
} from "src/utils/password-validator";
import { environment } from "src/config/environment";
import { Country } from "src/interfaces/country";
import { PopperProps } from "@material-ui/core";

interface IPasswordInputProps {
  onChange(
    event: ChangeEvent<HTMLInputElement>, // eslint-disable-line no-unused-vars
    state?: string, // eslint-disable-line no-unused-vars
    helpText?: string // eslint-disable-line no-unused-vars
  ): void;
  // eslint-disable-next-line no-unused-vars
  onKeyDown(event: KeyboardEvent<HTMLInputElement>): void;
  i18n: I18n;
  placeholder: string;
  helpText?: string;
  state?: string;
  value?: string;
  style?: CSSProperties;
  className?: string;
  country?: Country;
}

const getForbiddenWordList = (i18n: I18n): string => {
  const forbiddenWords = environment.forbiddenWords;
  const words = [...forbiddenWords];
  const last = words.pop();
  return `"${words.join('", "')}" ${i18n.get("or")} "${last}"`;
};

export const PasswordInput = ({
  i18n,
  onChange,
  onKeyDown,
  placeholder,
  helpText,
  state,
  style,
  className,
  country,
}: IPasswordInputProps): React.JSX.Element => {
  const forbiddenWords = environment.forbiddenWords;
  const minPasswordLength = environment.minPasswordLength;
  const maxPasswordLength = environment.maxPasswordLength;
  const [isEmpty, setIsEmpty] = useState(true);
  const [validation, setValidation] = useState(
    getInvalidPasswordValidationData()
  );
  const [matchedForbiddenWords, setMatchedForbiddenWords] = useState<
    null | string
  >(null);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? "";
    const isEmpty = newValue === "";
    const validation = passwordValidator(
      newValue,
      forbiddenWords,
      minPasswordLength,
      maxPasswordLength
    );
    const validationState = isEmpty
      ? ""
      : validation.isValid
      ? "success"
      : "error";

    onChange(event, validationState);

    setIsEmpty(isEmpty);
    setValidation(validation);
    setMatchedForbiddenWords(
      validation.hasNoForbiddenWords
        ? null
        : getAllMatchedForbiddenWords(newValue, forbiddenWords)?.join(", ") ??
            null
    );
  };

  const forbiddenWordsText =
    country === "mx"
      ? `${country}:Without forbidden words`
      : "Without forbidden words";

  return (
    <div className={className} style={style}>
      <div className="natds-input-container passwordInput">
        <Input
          id="newPassword"
          type="password"
          placeholder={i18n.get(placeholder)}
          onChange={handleChange}
          helpText={i18n.get(helpText ?? "")}
          state={state}
          onKeyDown={onKeyDown}
        />
        <div
          style={{
            marginTop: "0.5em",
            display: "flex",
            justifyContent: "start",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasNumber}
            isEmpty={isEmpty}
            validationMessage="Minimum one number"
            exampleMessage="0, 1, 2, 3"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasLowerCase}
            isEmpty={isEmpty}
            validationMessage="Minimum one lowercase letter"
            // eslint-disable-next-line spellcheck/spell-checker
            exampleMessage="abc"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasUpperCase}
            isEmpty={isEmpty}
            validationMessage="Minimum one uppercase letter"
            exampleMessage="ABC"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasSpecialCharacter}
            isEmpty={isEmpty}
            validationMessage="Minimum one special character"
            exampleMessage="Ex.: @, %, $"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.isLong}
            isEmpty={isEmpty}
            validationMessage="Minimum ### characters"
            validationMessageParameter={String(minPasswordLength)}
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.isTooLong}
            isEmpty={isEmpty}
            validationMessage="Maximum ### characters"
            validationMessageParameter={String(maxPasswordLength)}
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasNoForbiddenWords}
            isEmpty={isEmpty}
            validationMessage={forbiddenWordsText}
            exampleMessage={matchedForbiddenWords}
            popoverInfoText={
              <>
                <strong>
                  {i18n.get("The password cannot contain the excerpts")}
                </strong>
                {` ${getForbiddenWordList(i18n)}`}
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

interface IPasswordMessageProps {
  i18n: I18n;
  isValid: boolean;
  isEmpty: boolean;
  validationMessage: string;
  validationMessageParameter?: string;
  exampleMessage?: string | null;
  popoverInfoText?: React.JSX.Element;
}

const PasswordMessage = ({
  i18n,
  isValid,
  isEmpty,
  validationMessage,
  validationMessageParameter,
  exampleMessage,
  popoverInfoText,
}: IPasswordMessageProps): React.JSX.Element => {
  const validationMessageTranslated = i18n.getByTemplate(
    validationMessage,
    validationMessageParameter
  );
  return (
    <div
      style={{
        marginTop: "0.1em",
        display: "flex",
        alignItems: "center",
        width: "100%",
        color: isEmpty ? "gray" : isValid ? "green" : "red",
        gap: "0.4em",
      }}
    >
      <div style={{ paddingTop: "0.2em" }}>
        <Icon
          name={isValid ? "outlined-action-check" : "outlined-alert-empty"}
          size="tiny"
        />
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          textAlign: "left",
          fontSize: 12,
          gap: "0.2em",
          flexWrap: "wrap",
        }}
      >
        {/* eslint-disable-next-line spellcheck/spell-checker */}
        <div style={{ whiteSpace: "nowrap" }}>
          {validationMessageTranslated}
        </div>
        <div>
          {popoverInfoText !== undefined ? (
            <PopoverInfo popoverInfoText={popoverInfoText} />
          ) : null}
        </div>
        <div>{exampleMessage ? `(${exampleMessage})` : null}</div>
      </div>
    </div>
  );
};

interface IPopoverInfoProps {
  popoverInfoText: React.JSX.Element;
  anchorEl?: PopperProps["anchorEl"];
}

const PopoverInfo = ({
  popoverInfoText,
}: IPopoverInfoProps): React.JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
  return (
    <div
      onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
      onMouseLeave={() => setAnchorEl(undefined)}
      onClick={(event) =>
        setAnchorEl(anchorEl !== undefined ? undefined : event.currentTarget)
      }
    >
      <Popover
        open={anchorEl !== undefined}
        maxWidth={0}
        anchorEl={anchorEl}
        placement="top"
      >
        {popoverInfoText}
      </Popover>
      <Icon
        name="outlined-alert-info"
        style={{
          paddingTop: "0.2em",
          color: "gray",
          fontSize: 18,
          fontWeight: 1000,
        }}
      />
    </div>
  );
};
