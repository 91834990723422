import { RouteComponentProps } from "react-router-dom";
import { validate } from "class-validator";
import { Country } from "../interfaces/country";
import { isRouteSocialSignIn } from "./query-params-utils";
import { HostedUIQueryParamsLogout } from "./hosted-ui-query-params-logout";

export class HostedUIQueryValidatorLogout {
  // eslint-disable-next-line no-unused-vars
  constructor(private readonly props: RouteComponentProps) {}

  public async validateOrReject(): Promise<void> {
    if (isRouteSocialSignIn()) {
      return;
    }
    const params = this.getParams();
    const errors = await validate(params);
    if (errors.length > 0) {
      const messages = [];
      for (const error of errors) {
        if (error.constraints !== undefined) {
          for (const key of Object.keys(error.constraints)) {
            messages.push(error.constraints[key]);
          }
        }
      }
      throw new Error(messages.join(", "));
    }
  }

  public getParams(): HostedUIQueryParamsLogout {
    const params = new HostedUIQueryParamsLogout();
    params.country = (this.getQueryParamByName("country")?.toLowerCase() ??
      "") as Country;
    params.redirect_uri = this.getQueryParamByName("redirect_uri") ?? "";

    return params;
  }

  private getQueryParamByName(name: string) {
    const searchInfo = this.props.location.search;
    const query = new URLSearchParams(searchInfo);
    return query.get(name);
  }
}
