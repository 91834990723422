/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, IsOptional } from "class-validator";
import type { Country } from "../interfaces/country";
import type { Language } from "../interfaces/language";

export class HostedUIQueryParams {
  @IsNotEmpty({
    message: "parameter 'country' is missing",
  })
  public country!: Country;

  @IsNotEmpty({
    message: "parameter 'company' is missing",
  })
  public company!: string;

  @IsNotEmpty({
    message: "parameter 'language' is missing",
  })
  public language!: Language;

  @IsNotEmpty({
    message: "parameter 'redirect_uri' is missing",
  })
  public redirectUri!: string;

  @IsNotEmpty({
    message: "parameter 'client_id' is missing",
  })
  public clientId!: string;

  @IsOptional()
  public responseType?: string;

  @IsOptional()
  public session?: string;

  @IsOptional()
  public username?: string;

  @IsOptional()
  public destination?: string;

  @IsOptional()
  public previousPage?: string;

  @IsOptional()
  public code?: string;

  @IsOptional()
  public errorDescription?: string;

  @IsOptional()
  public sso?: string;

  @IsOptional()
  public maintenance?: string;

  @IsOptional()
  public state?: string;

  @IsOptional()
  public codeChallenge?: string;

  @IsOptional()
  public codeChallengeMethod?: string;
}
