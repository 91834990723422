import React from "react";
import { Container } from "../components/container";
import { showCountryFlag } from "src/utils/elo-utils";
import { Country } from "src/interfaces/country";
import { BffApi } from "src/api/bff-api";
import { I18n } from "src/I18n/I18n";
import { CookiesService } from "src/utils/cookie-service";
import { RouteComponentProps } from "react-router-dom";
import { HostedUIQueryValidatorLogout } from "src/utils/hosted-ui-query-validator-logout";

export enum Company {
  NATURA = "natura",
  AVON = "avon",
}

export interface ILogoutProps extends RouteComponentProps {
  api: BffApi;
  i18n: I18n;
  cookies: CookiesService;
}

export interface ILogoutState {
  redirect_uri: string;
  country: Country;
  company?: string;
}

export class Logout<
  Props extends ILogoutProps,
  State extends ILogoutState
> extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    try {
      const queryValidator = new HostedUIQueryValidatorLogout(this.props);
      await queryValidator.validateOrReject();
      const params = queryValidator.getParams();
      this.setState({
        ...params,
        company: Company.NATURA,
      } as State);

      await this.handleLogoutUser();
    } catch (error) {
      this.props.history.push("/error", error);
    }
  }

  public render(): React.JSX.Element {
    return <Container company={showCountryFlag(this.state?.country)} />;
  }

  private async handleLogoutUser(): Promise<void> {
    const cookieName = this.getCookieName();
    const ssoToken = this.props.cookies.getCookie(cookieName);
    try {
      await this.props.api.signOut(ssoToken);
    } catch (err) {
      console.log("failed to sign-out api in logout", err);
    } finally {
      this.eraseUserSession();
      this.goToRedirectURI(this.state.redirect_uri);
    }
  }

  private goToRedirectURI(redirect_uri: string): void {
    window.location.href = redirect_uri;
  }

  private eraseUserSession(): void {
    const cookieName = this.getCookieName();
    this.props.cookies.removeCookie(cookieName);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state.company}_id`;
  }
}
