/* eslint-disable spellcheck/spell-checker */
import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import { Select, Typography } from "@naturacosmeticos/natds-web";
import { isNotEmpty } from "class-validator";
import { ApiError } from "src/interfaces/api-error";
import { eloIsEnabled, showCountryFlag } from "src/utils/elo-utils";
import { environment } from "src/config/environment";
import parse from "html-react-parser";
import {
  getConsultantDocumentsData,
  getConsultantDocumentsSelectList,
  getDefaultSelectValue,
  isConsultantDocuments,
} from "src/utils/consultant-documents-data";
import { Country } from "src/interfaces/country";
import {
  getFirstAccessButtonStyling,
  getFirstAccessInputStyling,
  getLoginButtonStyling,
  getLoginInputStyling,
  getSubTitleStyling,
  validateLoginDocumentsDataByCountry,
} from "src/utils/styling-handler";
import {
  getLoginDefaultSelectValue,
  getLoginDocumentsData,
  handleStorageDocumentValue,
} from "src/utils/login-documents-data";
import { AppContext, FederationExtraInfo, OTPFlow } from "src/utils/context";
import Inputmask from "inputmask";
import { validate } from "rut.js";
import { validateEcuadorDocument } from "src/utils/ecuador-validation";
import crypto from "crypto-js";
import OTPFederationInfo from "src/components/otp-federation-info";
import { phoneSVG } from "src/assets/phones/phone";
import { cellPhoneSVG } from "src/assets/phones/cellphone";
import { ProviderType } from "src/utils/federation-utils";

const countriesWithSMSResend = environment.smsConfig.resend?.countries ?? [];

export type IOTPIdentificationProps = IAuthPieceProps;

export interface IOTPIdentificationState extends IAuthPieceState {
  username?: string;
  usernameState?: string;
  usernameHelpText?: string;
  consultantDocument?: string;
  consultantDocumentSelectState: "error" | "success" | undefined;
  consultantDocumentHelpText?: string;
  isSocialSigninError?: boolean;
}

export class OTPIdentification extends AuthPiece<
  IOTPIdentificationProps,
  IOTPIdentificationState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  static contextType = AppContext;

  context!: React.ContextType<typeof AppContext>;

  public constructor(props: IOTPIdentificationProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.refButton = React.createRef();
    this.setState({
      username: "",
      usernameState: "",
      usernameHelpText: "",
      consultantDocument: "",
      consultantDocumentHelpText: "",
    });
  }

  public async componentDidMount(): Promise<void> {
    this.context.otpContext.reset();
    await super.componentDidMount();
    const consultantDocument = environment.loginByDocumentCountries.includes(
      this.state?.country
    )
      ? getLoginDefaultSelectValue(this.state?.country)
      : getDefaultSelectValue(this.state?.country);

    this.setState({
      consultantDocument,
      isSocialSigninError: this.isSocialSigninError(),
    });
  }

  private isSocialSigninError(): boolean {
    return (
      this.state.previousPage === "social-sign-in" &&
      this.state.errorDescription
        ?.toLowerCase()
        ?.includes("social-signin-error") === true
    );
  }

  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  public render(): React.JSX.Element {
    const country = this.state?.country ?? "";
    const useLoginStyle = validateLoginDocumentsDataByCountry(country);
    return (
      <Container
        country={showCountryFlag(this.state?.country)}
        company={this.state?.company}
        isLoading={this.state?.isLoading}
      >
        {this.subTitle()}
        {!country || useLoginStyle
          ? null
          : this.selectConsultantDocumentsData()}
        <div className="row" style={styles.centerRow}>
          <div
            style={
              useLoginStyle
                ? getLoginInputStyling(country)
                : getFirstAccessInputStyling(country)
            }
          >
            {useLoginStyle ? this.selectLoginDocumentsData() : null}
            <Input
              id="username"
              type="text"
              placeholder={this.props.i18n.getUsernamePlaceholder(
                country,
                (localStorage.getItem("selectedOption") as string) ??
                  (this.state?.consultantDocument as string)
              )}
              onChange={(event: ChangeEvent<HTMLInputElement>) => {
                this.handleUsernameOnChange(event, country);
              }}
              onBlur={() =>
                ["rut", "email", "cedulaIdentificacion, ppt"].includes(
                  this.state?.consultantDocument as string
                ) && this.validUserTypeInput()
              }
              state={this.state?.usernameState}
              helpText={this.state?.usernameHelpText}
              onKeyDown={this.handleOnKeyDown}
              value={this.state?.username ?? ""}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div
            style={
              useLoginStyle
                ? getLoginButtonStyling(country)
                : getFirstAccessButtonStyling(country)
            }
          >
            <Button
              id="sendButton"
              onClick={async (): Promise<void> => {
                await this.onSendButtonClick();
              }}
              text={this.handleSubmitButtonText(this.state?.country)}
              itemRef={this.refButton}
              disabled={
                !this.state?.username ||
                this.state?.usernameState === "error" ||
                !this.state?.consultantDocument ||
                this.state?.consultantDocumentSelectState === "error"
              }
            />
          </div>
        </div>

        <div className="row" style={styles.centerRow}>
          <div
            style={
              useLoginStyle
                ? getLoginButtonStyling(country)
                : getFirstAccessButtonStyling(country)
            }
          >
            {this.state?.isSocialSigninError && (
              <>
                <OTPFederationInfo
                  infoType="bottominfo"
                  country={this.state?.country}
                />
                <OTPFederationInfo
                  infoType="contactinfo"
                  country={this.state?.country}
                />
              </>
            )}
          </div>
        </div>
      </Container>
    );
  }

  private async onSendButtonClick(): Promise<void> {
    try {
      this.setState({ isLoading: true });
      if (!this.validUserInput()) {
        return;
      }

      if (!this.validUserTypeInput()) {
        return;
      }

      const username = crypto.AES.encrypt(
        this.state?.username?.replace(/ /g, "") as string,
        environment.encryptionKey
      ).toString();
      const consultantDocument = this.state?.consultantDocument;

      const userInfo = await this.props.api.getUserInfo({
        country: this.state?.country as string,
        company: this.state?.company,
        clientId: this.state?.clientId,
        redirectUrl: this.state?.redirectUri,
        username,
        document: consultantDocument,
      });

      const isSocialSigninError = this.state?.isSocialSigninError;
      this.props.cookies.createOrUpdateCookie("cookieParams", this.state);

      const flow = isSocialSigninError
        ? OTPFlow.FEDERATION_ERROR
        : OTPFlow.CHANGE_PASSWORD;

      let flowExtraInfo: FederationExtraInfo | undefined = undefined;

      if (isSocialSigninError) {
        const socialSignInId = this.state.errorDescription
          ?.split("social-signin-error_")?.[1]
          ?.replace(/[\s.]/g, "");

        const cookies = this.props.cookies.getCookie("cookieParams");

        flowExtraInfo = {
          key: socialSignInId as string,
          provider: cookies.provider as ProviderType,
        };
      }

      this.context.otpContext.setAll(
        this.state?.username?.replace(/ /g, "") as string,
        consultantDocument,
        userInfo.email,
        userInfo.phone,
        undefined,
        undefined,
        flow,
        flowExtraInfo
      );

      const previousPage = "otp-identification";
      this.setState({ previousPage });
      this.navigate("otp-verification", { ...this.state, previousPage });
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleApiErrors(error: ApiError): void {
    const previousPage = "otp-identification";
    if (this.isInvalidError(error) || this.isNotFoundError(error)) {
      this.setState({ previousPage });
      this.navigate("otp-verification", { ...this.state, previousPage });
    } else {
      this.setState({ previousPage });
      this.navigate("error-validate-otp", { ...this.state, previousPage });
    }
  }

  private isNotFoundError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      error.status === 404 ||
      message.includes("failed to create") ||
      message.includes("user does not exist") ||
      message.includes("username not found")
    );
  }

  private isInvalidError(error: ApiError): boolean {
    return error.message
      .toLowerCase()
      .includes("user cannot perform this action");
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    if (
      isConsultantDocuments(this.state?.country) &&
      (this.state?.consultantDocument === undefined ||
        this.state?.consultantDocument === "")
    ) {
      this.setSelectDocumentsError();
      isUserInputValid = false;
    }
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private validUserTypeInput(): boolean {
    const { consultantDocument, username, country } = this.state;

    if ((username?.length as number) === 0) {
      return true;
    }

    const isLoginByDocumentCountry =
      environment.loginByDocumentCountries.includes(country);
    let isUserInputValid = true;

    switch (consultantDocument) {
      case "rut":
        if (!validate(username as string) && isLoginByDocumentCountry) {
          this.setState({
            usernameState: "error",
            usernameHelpText: this.props.i18n.get("Enter a valid Rut document"),
          });
          isUserInputValid = false;
        }
        break;
      case "cedulaIdentificacion":
        if (
          !validateEcuadorDocument(username as string) &&
          isLoginByDocumentCountry
        ) {
          this.setState({
            usernameState: "error",
            usernameHelpText: this.props.i18n.get("Enter a valid CI document"),
          });
          isUserInputValid = false;
        }
        break;

      case "ppt":
        if (!/^\d{10}$/.test(username as string) && isLoginByDocumentCountry) {
          this.setState({
            usernameState: "error",
            usernameHelpText: this.props.i18n.get("Enter a valid PPT document"),
          });
          isUserInputValid = false;
        }
        break;
      case "email": {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailPattern.test(username as string)) {
          this.setState({
            usernameState: "error",
            usernameHelpText: this.props.i18n.get("Enter a valid email"),
          });
          isUserInputValid = false;
        }
        break;
      }
      default:
        break;
    }

    return isUserInputValid;
  }

  private setUsernameError() {
    let message = "Field 'Consultant code or email' cannot be empty";
    if (isConsultantDocuments(this.state?.country)) {
      message = "Enter your data according to the type of information selected";
    }
    this.setState({
      usernameHelpText: this.props.i18n.get(message),
      usernameState: "error",
    });
  }

  private setSelectDocumentsError() {
    this.setState({
      consultantDocumentHelpText: this.props.i18n.get(
        "Please choose an option"
      ),
      consultantDocumentSelectState: "error",
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      this.refButton.current?.click();
    }
    if (
      this.state?.consultantDocument === "rut" &&
      event.key.toLowerCase() === "backspace"
    ) {
      event.currentTarget.value = event.currentTarget.value.slice(0, -1);
      this.setState({ username: event.currentTarget.value });
    }
  }
  public getWarningTextPe(): string {
    const { i18n } = this.props;
    const text = i18n.get(
      "Enter your consultant code and/or email and receive instructions to recover your password. If you don't have an email, contact the customer service center."
    );
    const phoneText = i18n.get("0800-80-510 for calls from a landline");
    const cellPhoneText = i18n.get("01700-9300 for calls from cell phone");

    const renderContactInfo = (icon: string, text: string): string => `
    <div>
      <img src="${icon}" alt="" height="15px" width="16px" /> ${text}
    </div>
  `;

    return `
    <span>
      ${text}
      ${renderContactInfo(phoneSVG, phoneText)}
      ${renderContactInfo(cellPhoneSVG, cellPhoneText)}
    </span>
  `;
  }

  public getWarningText(): string {
    let defaultText =
      "Enter your registered code and receive your temporary password. If you do not have an email, contact customer service";
    if (countriesWithSMSResend.includes(this.state?.country)) {
      defaultText =
        "Enter your registered code and receive your temporary password. If you do not have an email or cell phone number, contact customer service";
    }
    const eloText = (link?: string): string => {
      const linkText = link
        ? ` o accede al siguiente enlace: <a href="${link}">clic aquí</a>`
        : "";

      return `<span>Selecciona con cuál de tus datos deseas identificarte.</br></br>
              Para actualizar tus datos, contacta a nuestra Asistente Virtual por WhatsApp al número +55 11 93038-0000${linkText}.<span>`;
    };

    const eloTextMX = (link?: string): string => {
      const linkText = link
        ? ` o si deseas actualizar tus datos, da <a href="${link}">clic aquí</a>`
        : "";

      return `<span>Ingresa tu código de consultor y recibe tu código de verificación.</br></br>
              Si no cuentas con tu código de consultor contacta a nuestra Asistente virtual por WhatsApp al número +55 11 93038-0000${linkText}.<span>`;
    };

    switch (this.state?.country.toLocaleLowerCase()) {
      case "my":
        return "Enter your registered e-mail address and receive your temporary password. If you do not have an email, contact customer service";
      case "pe":
        return this.getWarningTextPe();
      case "co":
        if (eloIsEnabled("co")) {
          // eslint-disable-next-line spellcheck/spell-checker
          return "Enter your Consulting code and receive instructions to recover your password. If you do not have an email, contact our line 018000969010 or 6015188505.";
        }
        return defaultText;
      case "cl":
        return eloText(
          "https://plataformasnatura.com/portal_natura_&_co_ayuda"
        );
      case "mx":
        return eloTextMX("https://update.registronatura.net/");
      case "ec":
        return "Ingresa tu cédula de identidad y recibe tu código de verificación. Si no tienes un correo electrónico, comunícate con atención al cliente.";
      default:
        return defaultText;
    }
  }

  private subTitle(): React.JSX.Element {
    return (
      <div className="row" style={styles.centerRow}>
        <div
          style={getSubTitleStyling(this.state?.country) as React.CSSProperties}
        >
          {this.state?.isSocialSigninError ? (
            <OTPFederationInfo
              infoType="topinfo"
              country={this.state?.country}
            />
          ) : (
            <Typography variant="caption" style={{ fontSize: "16px" }}>
              {parse(this.props.i18n.get(this.getWarningText()))}
            </Typography>
          )}
        </div>
      </div>
    );
  }

  private selectConsultantDocumentsData(): React.JSX.Element {
    const country = this.state?.country;
    const data = getConsultantDocumentsData(country);
    if (!(data !== undefined && eloIsEnabled(country))) {
      return <></>;
    }
    const options = getConsultantDocumentsSelectList(data);
    return (
      <div className="row" style={styles.centerRow}>
        <div style={{ width: "328px", margin: "10px", textAlign: "left" }}>
          <Select
            placeholder={this.props.i18n.get("Choose your ID")}
            defaultValue=""
            onChange={(event: ChangeEvent<{ value: unknown }>): void =>
              this.setState({
                consultantDocument: event.target.value as string,
                consultantDocumentSelectState: undefined,
                consultantDocumentHelpText: "",
              })
            }
            options={options}
            state={this.state?.consultantDocumentSelectState}
            helpText={this.state?.consultantDocumentHelpText}
          />
        </div>
      </div>
    );
  }

  private selectLoginDocumentsData(): React.JSX.Element {
    const country = this.state?.country;
    const data = getLoginDocumentsData(country);
    if (
      !(
        data !== undefined &&
        environment.loginByDocumentCountries?.includes(country)
      )
    ) {
      return <></>;
    }
    const localValue = handleStorageDocumentValue(country);
    const defaultValue =
      this.state?.consultantDocument ??
      localValue ??
      getLoginDefaultSelectValue(country);
    const options = getConsultantDocumentsSelectList(data);
    return (
      <div className="row" style={styles.centerRow}>
        <div style={styles.select}>
          <Select
            defaultValue={defaultValue}
            onChange={(event: ChangeEvent<{ value: unknown }>) => {
              this.setState({
                consultantDocument: event.target.value as string,
                consultantDocumentSelectState: undefined,
                consultantDocumentHelpText: "",
                username: "",
              });
              localStorage.setItem(
                "selectedOption",
                event.target.value as string
              );
              this.handleInputMask(this.state.country);
            }}
            options={options}
            state={this.state?.consultantDocumentSelectState}
            helpText={this.state?.consultantDocumentHelpText}
          />
        </div>
      </div>
    );
  }
  private handleUsernameOnChange(
    event: React.ChangeEvent<HTMLInputElement>,
    country: Country
  ): void {
    const selector = document.getElementById("username") as HTMLInputElement;

    this.setState({
      usernameState: "",
      username: event.currentTarget.value,
      usernameHelpText: "",
    });

    if (!environment.loginByDocumentCountries.includes(country)) {
      return;
    }

    if (this.state?.consultantDocument === "rut") {
      Inputmask({
        mask: ["x.999.999-K", "x9(.999){2}-K"],
        showMaskOnHover: false,
        showMaskOnFocus: false,
        autoUnmask: true,
        keepStatic: true,
        clearIncomplete: false,
        clearMaskOnLostFocus: true,
        definitions: {
          K: {
            validator: "[0-9|Kk]",
          },
          x: {
            validator: "[1-9]",
          },
        },
      }).mask(selector);
      return;
    }

    if (this.state?.consultantDocument === "cedulaIdentificacion") {
      Inputmask({
        mask: ["999999999-9"],
        showMaskOnHover: false,
        keepStatic: true,
        showMaskOnFocus: false,
        autoUnmask: true,
        clearIncomplete: false,
        clearMaskOnLostFocus: true,
      }).mask(selector);
    }

    if (this.state?.consultantDocument === "ppt") {
      Inputmask({
        mask: "9999999999",
        showMaskOnHover: false,
        keepStatic: false,
        showMaskOnFocus: false,
        autoUnmask: true,
        clearIncomplete: false,
        clearMaskOnLostFocus: true,
      }).mask(selector);
    }
  }

  private handleInputMask(country: Country): void {
    const selector = document.getElementById("username") as HTMLInputElement;
    Inputmask("", {
      placeholder: this.props.i18n.getUsernamePlaceholder(
        country,
        (localStorage.getItem("selectedOption") as string) ??
          (this.state?.consultantDocument as string)
      ),
      clearMaskOnLostFocus: true,
      showMaskOnHover: false,
      showMaskOnFocus: false,
    })
      .mask(selector)
      .remove();
  }

  private handleSubmitButtonText(country: string): string {
    const { isSocialSigninError } = this.state || {};

    if (isSocialSigninError) {
      return country === "co"
        ? this.props.i18n.get("confirm")
        : this.props.i18n.get("Proceed");
    }

    return this.props.i18n.get("Send");
  }
}
