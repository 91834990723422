export class PasswordValidationData {
  public isValid = true;

  public isLong = true;

  public isTooLong = true;

  public hasNoSequence = true;

  public hasNumber = true;

  public hasLowerCase = true;

  public hasUpperCase = true;

  public hasSpecialCharacter = true;

  public hasNoForbiddenWords = true;
}
