import React from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Container } from "../components/container";
import { setTitle, showCountryFlag } from "src/utils/elo-utils";
import {
  ProgressIndicator,
  Typography,
  Icon,
} from "@naturacosmeticos/natds-web";
import styles from "src/styles/styles";
import { Button } from "../components";
import { redirect } from "src/utils/redirect";
import { decideQuerySeparator } from "src/utils/query-builder";
import { I18n } from "src/I18n/I18n";
import { dictionary } from "src/I18n/dictionary";
import parse from "html-react-parser";
import { IconName } from "@naturacosmeticos/natds-icons";
import { ErrorType } from "src/interfaces/enum-error-type";
import { ICookieParams, getFederationUrl } from "src/utils/federation-utils";
import { Country } from "src/interfaces/country";

export type ISocialSignInProps = IAuthPieceProps;

export interface ISocialSignInState extends IAuthPieceState {
  loading?: boolean;
  cookieParams?: ICookieParams;
  iconName: IconName | undefined;
  iconColor: string;
  errorTitleMessage: string;
  errorMessage: string;
  showErrorScreen: boolean;
  isBindIDProvider: boolean;
}

export class SocialSignIn extends AuthPiece<
  ISocialSignInProps,
  ISocialSignInState
> {
  public constructor(props: ISocialSignInProps) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    const i18n = new I18n(dictionary);
    const cookies = this.props.cookies.getCookie(
      "cookieParams"
    ) as ICookieParams;
    setTitle(i18n, cookies.country, cookies.language);
    this.setState({
      loading: true,
      cookieParams: cookies,
      showErrorScreen: false,
      isBindIDProvider: false,
      iconName: undefined,
      iconColor: "",
      errorTitleMessage: "",
      errorMessage: "",
    });
    this.socialLogin();
  }

  public render(): React.ReactNode {
    return (
      <>
        {this.loadingScreen()}
        {this.errorScreen()}
      </>
    );
  }

  private errorScreen(): React.ReactNode {
    if (this.state?.showErrorScreen) {
      return (
        <Container
          country={showCountryFlag(this.state?.cookieParams?.country, true)}
          company={this.state?.cookieParams?.company}
        >
          <div
            style={{
              display: "flex",
              marginBottom: "15px",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {this.showTitle()}
            {this.showIcon()}
            {this.showMessage()}
          </div>
          <div className="row" style={styles.centerRow}>
            {this.showButton()}
          </div>
        </Container>
      );
    }
    return <></>;
  }

  private loadingScreen(): React.ReactNode {
    if (this.state?.loading) {
      return (
        <div style={{ ...styles.alingCenter, height: "100vh" }}>
          <ProgressIndicator size={64}></ProgressIndicator>
        </div>
      );
    }
    return <></>;
  }

  private redirectButtonClick(): void {
    redirect(
      `../?client_id=${this.state?.cookieParams?.clientId}&country=${this.state?.cookieParams?.country}&company=${this.state?.cookieParams?.company}&language=${this.state?.cookieParams?.language}&redirect_uri=${this.state?.cookieParams?.redirectUri}`
    );
  }

  private async socialLogin(): Promise<void> {
    const code = this.state?.code;
    const errorDescription = this.state?.errorDescription?.toLowerCase();
    if (errorDescription !== undefined && errorDescription !== "") {
      this.errorCheck(errorDescription);
      return;
    }
    await this.signin(code as string);
  }

  private errorCheck(errorDescription: string): void {
    const error = errorDescription.toLowerCase();
    if (error.includes("social-signin-error")) {
      if (this.state?.cookieParams?.country === "br") {
        this.showErrorScreen(ErrorType.USER_NOT_FOUND);
        return;
      }
      const previousPage = "social-sign-in";
      this.setState({ previousPage });
      this.navigate("otp-identification", {
        ...this.state,
        ...this.state.cookieParams,
        errorDescription,
        previousPage,
      });
      return;
    }
    if (error.includes("re_authentication_required_google")) {
      this.goBackToSocialLoginPage("Google");
      return;
    }
    if (error.includes("re_authentication_required_facebook")) {
      this.goBackToSocialLoginPage("Facebook");
      return;
    }
    if (error.includes("re_authentication_required_oam")) {
      this.goBackToSocialLoginPage("OAM");
      return;
    }
    if (
      error.includes("re_authentication_required_bindid") ||
      error.includes("re_authentication_required_bindidnoelo")
    ) {
      this.showErrorScreen(ErrorType.RE_AUTHENTICATION_REQUIRED_BINDID);
      return;
    }
    if (error.includes("user_not_found")) {
      this.showErrorScreen(ErrorType.USER_NOT_FOUND);
      return;
    }
    if (error.includes("problems_with_registration_contact_support")) {
      this.redirectButtonClick();
    }
    this.showErrorScreen(ErrorType.GENERIC_ERROR);
  }

  private async signin(code: string): Promise<void> {
    try {
      const signInResult = await this.props.api.socialSignIn({
        code,
        clientId: this.state?.cookieParams?.clientId as string,
        company: this.state?.cookieParams?.company as string,
        country: this.state?.cookieParams?.country as string,
        redirectUri: `https://${window.location.hostname}/social-sign-in`,
        redirectAppUri: this.state?.cookieParams?.redirectUri as string,
        provider: this.state?.cookieParams?.provider as string,
        responseType: this.state?.cookieParams?.responseType,
        codeChallenge: this.state?.cookieParams?.codeChallenge,
        codeChallengeMethod: this.state?.cookieParams?.codeChallengeMethod,
      });
      await this.saveUserSession(signInResult.sso_token);
      this.goBackToApplication(
        signInResult.sso_token,
        signInResult.code,
        this.state?.cookieParams?.state
      );
    } catch (e) {
      const error = e as Error;
      if (error.message.includes("User country do not mach")) {
        this.showErrorScreen(ErrorType.USER_COUNTRY_NOT_MACH);
      } else {
        this.showErrorScreen(ErrorType.GENERIC_ERROR);
      }
    }
  }

  private async saveUserSession(ssoToken: string): Promise<void> {
    const cookieName =
      `${this.state?.cookieParams?.country}_${this.state?.cookieParams?.company}_id`.toLowerCase();
    this.props.cookies.createCookie(cookieName, ssoToken);
  }

  private goBackToApplication(
    ssoToken: string,
    code?: string,
    state?: string
  ): void {
    const redirectUri = this.state?.cookieParams?.redirectUri as string;
    const redirectUrl = `${redirectUri}${decideQuerySeparator(redirectUri)}`;
    const stateParam = state === undefined ? "" : `&state=${state}`;
    if (code === undefined) {
      redirect(`${redirectUrl}sso_token=${ssoToken}`);
    } else {
      redirect(`${redirectUrl}code=${code}${stateParam}`);
    }
  }

  private goBackToSocialLoginPage(identity: string): void {
    this.setState({
      errorDescription: "",
    });
    const federationUrl = getFederationUrl(
      this.state?.cookieParams?.country as string,
      identity,
      this.state?.cookieParams?.clientId as string
    );
    redirect(federationUrl);
  }

  private showErrorScreen(errorType: ErrorType): void {
    const country = this.state?.cookieParams?.country as Country;
    const countriesWithNaturaTextOnly = ["mx"];
    const isNaturaTextOnlyCountry =
      countriesWithNaturaTextOnly.includes(country);
    let errorTitleMessage = "",
      errorMessage = "",
      isBindIDProvider = false,
      iconName: IconName | undefined = undefined,
      iconColor = "";
    switch (errorType) {
      case ErrorType.USER_NOT_FOUND:
        if (!this.isRedirectSsoOam()) {
          errorTitleMessage = isNaturaTextOnlyCountry
            ? `${country}:User not found in cognito`
            : "User not found in cognito";
          errorMessage = `${country}:User not found in cognito, please check if the social account email is the same as the elo account email.`;
        }
        break;
      case ErrorType.USER_COUNTRY_NOT_MACH:
        errorTitleMessage = "Registration not found in the country";
        errorMessage = "Your record was not found in the selected country.";
        break;
      case ErrorType.RE_AUTHENTICATION_REQUIRED_BINDID:
        errorTitleMessage = "Login without password";
        errorMessage =
          "Registration successfully Complete. Click the button below to login.";
        iconName = "outlined-action-check";
        iconColor = "#569a32";
        isBindIDProvider = true;
        break;
      default:
        errorTitleMessage = "";
        errorMessage =
          "We were unable to process your request. Please try again later.";
        break;
    }
    this.setState({
      errorTitleMessage,
      errorMessage,
      iconName,
      iconColor,
      isBindIDProvider,
      loading: false,
      showErrorScreen: true,
    });
  }

  private translateText(text: string): string {
    const i18n = new I18n(dictionary);
    const cookies = this.props.cookies.getCookie(
      "cookieParams"
    ) as ICookieParams;
    return i18n.getByLanguage(cookies.language, text, text) as string;
  }

  private showIcon(): React.ReactNode {
    if (this.state?.iconName === undefined) {
      return <></>;
    }
    return (
      <div
        style={{
          color: this.state?.iconColor,
          marginBottom: "10px",
          marginTop: "10px",
        }}
      >
        <Icon name={this.state?.iconName} size="large" color="inherit" />
      </div>
    );
  }

  private showTitle(): React.ReactNode {
    if (this.state?.errorTitleMessage === "") {
      return <></>;
    }
    return (
      <div className="natds-input-container">
        <Typography
          variant="caption"
          style={{ fontSize: "18px", fontWeight: "500" }}
        >
          {parse(this.translateText(this.state?.errorTitleMessage))}
        </Typography>
      </div>
    );
  }

  private showMessage(): React.ReactNode {
    return (
      <div className="natds-input-container">
        <Typography variant="caption" style={{ fontSize: "15px" }}>
          {parse(this.translateText(this.state?.errorMessage))}
        </Typography>
      </div>
    );
  }

  private showButton(): React.ReactNode {
    return (
      <div style={{ margin: "10px", width: "300px" }}>
        <Button
          id="redirect"
          onClick={async (): Promise<void> => {
            this.state?.isBindIDProvider
              ? this.goBackToSocialLoginPage("BindID")
              : this.redirectButtonClick();
          }}
          text={this.translateText(
            this.state?.isBindIDProvider
              ? "Continue with BindID"
              : "Go back to Login"
          )}
          keepEnabled={true}
        />
      </div>
    );
  }

  private isRedirectSsoOam(): boolean {
    if (this.state?.cookieParams?.sso === "oam") {
      this.redirectButtonClick();
      return true;
    }
    return false;
  }
}
