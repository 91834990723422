import React, { ChangeEvent } from "react";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { Input } from "src/components";
import { Button } from "src/components/button";
import { Container } from "src/components/container";
import styles from "src/styles/styles";
import { isNotEmpty } from "src/utils/is-not-empty";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { ApiError } from "src/interfaces/api-error";
import { PasswordInput } from "src/components/password-input";
import { isTooManyRequestsError } from "src/utils/too-many-requests-validator";
import { attemptLimitExceededMessage } from "src/utils/attempt-limit-exceeded-message";
import { FIELD_COULD_NOT_BE_EMPTY } from "src/I18n/I18n-templates";
import { environment } from "src/config/environment";
import { ISignInState } from "./sign-in";

export interface IChangePasswordPieceState extends IAuthPieceState {
  session: string;
  username: string;
  usernameState?: string;
  usernameHelpText?: string;
  currentPassword: string;
  currentPasswordState?: string;
  currentPasswordHelpText?: string;
  newPassword: string;
  newPasswordState?: string;
  newPasswordHelpText?: string;
  confirmNewPassword: string;
  confirmNewPasswordState?: string;
  confirmNewPasswordHelpText?: string;
  formHelperErrorMessage?: string;
  formHelperState?: string;
}

export class ChangePassword extends AuthPiece<
  IAuthPieceProps,
  IChangePasswordPieceState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IAuthPieceProps) {
    super(props);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleUsernameOnChange = this.handleUsernameOnChange.bind(this);
    this.handleCurrentPasswordOnChange =
      this.handleCurrentPasswordOnChange.bind(this);
    this.handleNewPasswordOnChange = this.handleNewPasswordOnChange.bind(this);
    this.handleConfirmNewPasswordOnChange =
      this.handleConfirmNewPasswordOnChange.bind(this);
    this.validateInputs = this.validateInputs.bind(this);

    this.refButton = React.createRef();
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    const state = this.props.location.state as ISignInState;
    this.setState({
      consultantDocument: state?.consultantDocument,
    });
  }

  public render(): React.JSX.Element {
    return (
      <Container country={this.state?.country} company={this.state?.company}>
        <div className="row" style={{ ...styles.centerRow, textAlign: "left" }}>
          <div className="natds-input-container">
            <Typography variant="caption">
              {`${this.props.i18n.get(
                `Fill in the fields below to create a new password`
              )}`}
            </Typography>
          </div>
        </div>

        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Input
              id="username"
              type="text"
              value={this.state?.username}
              placeholder={this.props.i18n.getUsernamePlaceholder(
                this.state?.country ?? ""
              )}
              onChange={this.handleUsernameOnChange}
              disabled={this.isUsernameFromQueryParam()}
              helpText={this.state?.usernameHelpText}
              state={this.state?.usernameState}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Input
              id="currentPassword"
              type="password"
              placeholder={this.props.i18n.get("Current password")}
              onChange={this.handleCurrentPasswordOnChange}
              onKeyDown={this.handleOnKeyDown}
              helpText={this.state?.currentPasswordHelpText}
              state={this.state?.currentPasswordState}
            />
          </div>
        </div>
        <PasswordInput
          className="row"
          style={styles.centerRow}
          i18n={this.props.i18n}
          placeholder="New password"
          helpText={this.state?.newPasswordHelpText}
          value={this.state?.newPassword}
          state={this.state?.newPasswordState}
          onKeyDown={this.handleOnKeyDown}
          onChange={this.handleNewPasswordOnChange}
        />
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container passwordInput">
            <Input
              id="confirmNewPassword"
              type="password"
              placeholder={this.props.i18n.get("Confirm password")}
              onChange={this.handleConfirmNewPasswordOnChange}
              helpText={this.state?.confirmNewPasswordHelpText}
              state={this.state?.confirmNewPasswordState}
              onKeyDown={this.handleOnKeyDown}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container" style={styles.centerRow}>
            <FormHelperText
              state={this.state?.formHelperState as State}
              style={styles.helperText}
            >
              {this.state?.formHelperErrorMessage}
            </FormHelperText>
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Button
              id="firstAccessButton"
              onClick={async (): Promise<void> => {
                await this.onButtonClick();
              }}
              text={this.props.i18n.get("create new password")}
              itemRef={this.refButton}
              disabled={
                !this.state?.username ||
                !this.state?.currentPassword ||
                !this.state?.newPassword ||
                this.state?.newPasswordState !== "success" ||
                !this.state?.confirmNewPassword ||
                this.state?.confirmNewPasswordState !== "success"
              }
            />
          </div>
        </div>
      </Container>
    );
  }

  private handleUsernameOnChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState({
      username: event.target.value,
    });
    if (event.target.value) {
      this.setState({
        usernameState: "",
        usernameHelpText: "",
      });
    } else {
      this.setUsernameError();
    }
  }

  private handleCurrentPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    this.setState({
      currentPassword: event.target.value,
    });
    if (event.target.value) {
      this.setState({
        currentPasswordState: "",
        currentPasswordHelpText: "",
      });
    } else {
      this.setCurrentPasswordError();
    }
  }

  private handleNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>,
    newPasswordState = "",
    newPasswordHelpText = ""
  ): void {
    const newPassword = event.target.value;

    this.setState({
      newPassword,
      newPasswordHelpText,
      newPasswordState,
    });
    this.validateConfirmNewPassword(
      newPassword,
      this.state.confirmNewPassword ?? ""
    );
  }

  private handleConfirmNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    const confirmNewPassword = event.target.value;

    this.setState({
      confirmNewPassword,
    });
    this.validateConfirmNewPassword(
      this.state.newPassword ?? "",
      confirmNewPassword
    );
  }

  private validateConfirmNewPassword(
    newPassword: string,
    confirmNewPassword: string
  ): void {
    if (confirmNewPassword !== "") {
      const confirmNewPasswordState =
        confirmNewPassword === newPassword ? "success" : "error";
      const confirmNewPasswordHelpText =
        confirmNewPasswordState === "error"
          ? this.props.i18n.get("Passwords do not match")
          : "";

      this.setState({
        confirmNewPasswordState,
        confirmNewPasswordHelpText,
      });
    } else if (newPassword === "") {
      this.setState({
        confirmNewPasswordState: "",
        confirmNewPasswordHelpText: "",
      });
    } else {
      this.setConfirmNewPasswordError();
    }
  }

  private async onButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperState: "",
        formHelperErrorMessage: "",
      });
      if (!this.validateInputs()) {
        return;
      }

      await this.props.api.changePassword({
        country: this.state?.country,
        company: this.state?.company,
        username: this.state?.username?.replace(/ /g, ""),
        currentPassword: this.state?.currentPassword?.replace(/ /g, ""),
        newPassword: this.state?.newPassword?.replace(/ /g, ""),
        clientId: this.state?.clientId,
        document: environment.loginByDocumentCountries?.includes(
          this.state?.country
        )
          ? this.state.consultantDocument
          : undefined,
      });
      this.setState({
        previousPage: "change-password",
      });
      this.navigate("redirect", this.state);
    } catch (error) {
      if (!this.handleAcceptableApiErrors(error)) {
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(
            "The password cannot be changed, please communicate with customer support"
          ),
          formHelperState: "error",
        });
      }
    }
  }

  private handleAcceptableApiErrors(error: unknown): boolean {
    const apiError = error as ApiError;
    if (this.isNotFoundError(apiError)) {
      this.showNotFoundError();
      return true;
    }
    if (this.isInvalidPasswordError(apiError)) {
      this.showInvalidPasswordError();
      return true;
    }
    if (this.isBadRequestError(apiError)) {
      this.setState({
        formHelperErrorMessage: this.props.i18n.get(apiError.message),
        formHelperState: "error",
      });
      return true;
    }
    if (isTooManyRequestsError(apiError)) {
      this.showPasswordAttemptsExceededError();
      return true;
    }
    return false;
  }

  private isBadRequestError(error: ApiError) {
    return error.status === 400;
  }

  private showPasswordAttemptsExceededError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        attemptLimitExceededMessage()
      ),
      formHelperState: "error",
    });
  }

  private isUsernameFromQueryParam(): boolean {
    const searchInfo = this.props.location.search;
    const query = new URLSearchParams(searchInfo);
    return !!query.get("username");
  }

  private validateInputs(): boolean {
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      return false;
    }
    if (!isNotEmpty(this.state?.currentPassword)) {
      this.setCurrentPasswordError();
      return false;
    }
    if (!isNotEmpty(this.state?.newPassword)) {
      this.setNewPasswordError();
      return false;
    }
    if (!isNotEmpty(this.state?.confirmNewPassword)) {
      this.setConfirmNewPasswordError();
      return false;
    }
    if (this.state?.newPassword !== this.state?.confirmNewPassword) {
      this.setPasswordsMismatchError();
      return false;
    }
    return true;
  }

  private setUsernameError() {
    this.setState({
      usernameState: "error",
      usernameHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.getUsernamePlaceholder(this.state?.country ?? "")
      ),
    });
  }

  private setCurrentPasswordError() {
    this.setState({
      currentPasswordState: "error",
      currentPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Current password")
      ),
    });
  }

  private setNewPasswordError() {
    this.setState({
      newPasswordState: "error",
      newPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("New password")
      ),
    });
  }

  private setConfirmNewPasswordError() {
    this.setState({
      confirmNewPasswordState: "error",
      confirmNewPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Confirm new password")
      ),
    });
  }

  private setPasswordsMismatchError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("Passwords do not match"),
      formHelperState: "error",
    });
  }

  private isInvalidPasswordError(error: ApiError): boolean {
    return (
      error.message.toLowerCase().includes("incorrect username or password") ||
      error.message
        .toLowerCase()
        .includes("it was not possible to recognize username type for value")
    );
  }

  private showInvalidPasswordError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "Invalid username or password"
      ),
      formHelperState: "error",
    });
  }

  private isNotFoundError(error: ApiError): boolean {
    return error.status === 404;
  }

  private showNotFoundError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("User does not exists"),
      formHelperState: "error",
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }
}
