/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { IsNotEmpty, IsOptional } from "class-validator";
import type { Country } from "../interfaces/country";

export class HostedUIQueryParamsLogout {
  @IsNotEmpty({
    message: "parameter 'country' is missing",
  })
  public country!: Country;

  @IsNotEmpty({
    message: "parameter 'redirect_uri' is missing",
  })
  public redirect_uri!: string;
}
